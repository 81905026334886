import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Link,
    Heading,
    HeadingArrow,
    Text,
    Wrapper,
} from "./CallToActionSlimSection.styled";
import CallToActionSlimSectionProps from "./CallToActionSlimSectionProps";

const CallToActionSlimSection = ({
    identifier,
    heading,
    text,
    title,
    url,
    target,
    theme = "blue",
    contentTheme = "cyan",
    anchorName,
}: CallToActionSlimSectionProps): ReactElement => {
    return (
        <ThemeProvider
            theme={{
                theme: theme,
                contentTheme: contentTheme,
            }}
        >
            <Wrapper key={identifier} id={anchorName}>
                <Container>
                    <Link to={url} exact target={target} title={title}>
                        <Heading {...addEditAttributes("Heading")}>
                            {appendComponentWithNoBr(
                                heading,
                                <HeadingArrow
                                    icon={"longArrowRight35"}
                                    aria-hidden="true"
                                />,
                            )}
                        </Heading>
                        <Text {...addEditAttributes("Text")}>{text}</Text>
                    </Link>
                </Container>
            </Wrapper>
        </ThemeProvider>
    );
};

export default React.memo(CallToActionSlimSection);
